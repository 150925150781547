<template>
  <form @submit.prevent>
    <h1>Create Product</h1>
    <div class="field">
      <label>Title</label>
      <input type="text" v-model="product.name" />
    </div>
    <div class="field">
        <label>category</label>
        <input type="text" v-model="product.category" />
    </div>
    <div class="field">
        <label>color</label>
        <input type="text" v-model="product.color" />
    </div>
    <button class="ui button blue tiny" @click="productSaveButtonPressed">Save Product</button>
  </form>
</template>


<script>
// *** https://softauthor.com/vuejs-composition-api-reusable-module-vs-component/
import { reactive } from  "@vue/composition-api";
export default {

  setup(_, {emit}) {

    const product = reactive({
      name: "",
      category: "",
      color: ""
    });

    const productSaveButtonPressed = () => {

      emit("product", product);
    };

    return { product, productSaveButtonPressed };
  },
};
</script>
