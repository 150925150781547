<template>
<div>
  <ProductForm  @product="getProductData" />
    <ul>
       <h1> productList </h1>
    <li  v-for="(t, sf) in productList" :key="sf">
       {{ t }}
    </li>
  </ul>

  <h1> PRODUCTS </h1>
   <ul>
      
    <li  v-for="(t, aa) in products" :key="aa">
       {{ t.name }}
    </li>
  </ul>
</div>
  
</template>

<script>

// *** https://softauthor.com/vuejs-composition-api-reusable-module-vs-component/

import {  ref, onMounted, reactive } from "@vue/composition-api";
import ProductForm from "./ProductForm";
import useProduct from "./useProduct";

export default {
  components: {
    ProductForm,
  },
  setup() {
    const {
      addProduct,
      prdList,
      products
      // , loadProducts
    } = useProduct()

      const productList = ref([]);
      const key = ref(0);
      const plist = reactive({page: 1, pageCount: 20, items: []});
      // productList.value = onMounted( () => {
      //   // productList = [{ name: "เสื้อเชิ้ต", category: "เสื้อผ้า", color: "สีขาว" }, { name: "รองเท้าแตะ", category: "รองเท้า", color: "สีขาว" }]
      //   loadProductData
      // //   productList.value =  loadProducts;
      // //  console.log('loadProducts',productList.value);

      // });
    // const loadProductData = () => {
    //     productList.value = ["a","b","c"]
    // }
    onMounted(() => {
    init();
  });

  function init() {
    productList.value = [{name: "init"}];
    // plist.items =  [{name: "init"}];
  }

    const getProductData = (product) => {

     

       addProductData(product);
      
      // plist.items[0].name = product.name;
      // console.log("productList from FORM ", productList.value);
     
      
    };

     const addProductData = (pp) => {
        // productList.unshift(pp);
        let newpp = Object.assign({},pp);
         console.log('BEFORE ADD product ',prdList);
        productList.value.push(newpp);
        addProduct(pp);
        console.log('AFTER ADD product ',prdList);
     }

    return { products ,prdList, plist ,productList, getProductData , key };
  },
};
</script>
