
// *** https://softauthor.com/vuejs-composition-api-reusable-module-vs-component/
import { reactive, toRefs } from "@vue/composition-api";

export default function useProduct() {
  const state = reactive({
    products: [],
  });

  const prdList = [];



  const loadProducts = () => {


    state.products.push({ "name": "เสื้อเชิ้ต", "category": "เสื้อผ้า", "color": "สีขาว" });
    state.products.push({ "name": "รองเท้าแตะ", "category": "รองเท้า", "color": "สีขาว" });
    state.products.push({"name": "กางเกงยีนส์ แจ๊ค ดอว์ สั้น", "category": "เสื้อผ้า", "color": "น้ำเงิน"});

  }

  const addProduct = (product) => {

    // prdList.value.unshift(product);
    let newpp = Object.assign({},product);
    state.products.unshift(newpp);
    console.log('!! UPDATE state.products >>> ', state.products);
     console.log('!! UPDATE state.products >>> ',product);
        
  }

  return { ...toRefs(state), prdList, loadProducts, addProduct
  }
}
